<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="row p-3 justify-content-between">
          <div class="col-8 row">
            <div class="row ml-0 mr-4 align-items-center" v-for="(item, index) in colorArr" :key="`color-${index}`"
              v-show="displayCalendar">
              <div class="color-box" :style="`background-color: ${item}`"></div>
              <div>Part {{ index + 1 }}</div>
            </div>
          </div>
          <!--          <div class="col-4 row justify-content-end">
            <Button icon="pi pi-list" class='mr-2 button-display' :class="`${displayList ? 'active': ''}`" @click="changeDisplay('displayList', 'displayCalendar')"/>
            <Button icon="pi pi-calendar" class="button-display" :class="`${displayCalendar ? 'active': ''}`" @click="changeDisplay('displayCalendar', 'displayList')"/>
            </div>-->
        </div>
        <!--        <calendarTable
          v-if="displayCalendar"
        />-->
        <!--{{listData}}-->
        <BaseDatatable v-if="displayList" :key="componentKey" @onRowSelect="onRowSelect" :tableData="listData" :model="dataModel"
          :options="options" :filterDefault="filters" :getFilterOptions="getFilterOptions" :actions="getRowActions()"
          v-on:action-click="onActionClicked"></BaseDatatable>
        <!--    @onDataChange = 'DataOnChange'     -->

        <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
          Are you want to delete this REPORT ?
          <template #footer>

            <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
            <Button label="Confirm" @click="deleteData()" icon="pi pi-check"
                    class="p-button-danger  btn-confirm-delete-dialog" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
//import calendarTable from './calendar'
// import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    //calendarTable
    // Popup
  },
  data() {
    return {
      displayList: true,
      displayCalendar: false,
      filters: {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      countRelationship: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList: {
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: { _eq: false }
        }
      },
      page_transaction: null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      colorArr: [
        '#BBB2F9',
        '#F8D8AB',
        '#BBEFA8',
        '#A7C3FA',
        '#F3B1AF'
      ]
    }
  },
  mounted() {
    this.initParams();
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    console.log('this.modelName:', this.modelName);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if (this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_MANAGERMENT);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0) {
      this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
    }
    // console.log("arr_role:" ,arr_role)
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false : true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false : true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false : true;
    if (this.$route?.query && this.$route.query?.view == 'calendar') {
      this.changeDisplay('displayCalendar', 'displayList');
    }
    // var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
    // // console.log('role_clubrole_club',arr_role_clubs)
    // if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
    //   this.view_club = false;
    // }else{
    //   this.view_club = true;
    // }
    // console.log('this.view',this.view_club)
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    changeDisplay(activeData, unactiveData) {
      this.$set(this, activeData, true);
      this.$set(this, unactiveData, false);
      this.componentKey++;
    },
    async initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      await this.callbackOptions();
    },
    onRowSelect(data) {
      this.$router.push({path: `/admin/list-visit_report/edit/${data.id}`, query: { page_transaction: (new Date()).getTime().toString() }});
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      for (let index = 0; index < fields.length; index++) {
        var attributes = [];
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          var result;
          if (element['name'] == 'category_id') {
            where_ = {
              deleted: { _eq: false },
            }
          }
          if (element['name'] == 'channel_name') {
            where_ = {
              active: { _eq: true },
              deleted: { _eq: false },
            }
          }
          if (element['name'] == "service_name") {
            // console.log("element",element)
            let { data } = await this.$apollo.query({
              query: DataServices.getList("visit_report", { "fields": "id name" }),
              variables: {
                where_key: {
                  deleted: { _eq: false },
                  parent_id: { _is_null: true }
                },
                orderBy: [{ ordinal: 'desc' }, { created_at: 'desc' }]
              }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({ value: ele[attributes[0]], label: ele[attributes[1]] })
                }
              }
            }
          } else {
            if (element['name'] == 'group_channel_name') {
              where_ = {
                "channel": {
                  "deleted": {
                    "_eq": false
                  }
                },
                "deleted": {
                  "_eq": false
                }
              }
            } else {
              if (element.where_option) {
                where_ = element.where_option
              }
            }
            let { data } = await this.$apollo.query({
              query: DataServices.getList(element.models.tableName, { "fields": attributes.join(',') }),
              variables: { where_key: where_ }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({ value: ele[attributes[0]], label: ele[attributes[1]] })
                }
              }
            }
          }
          // console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        if (field.options) result = field.options;
        else if (field.models && this.options[name]) result = this.options[name];
      } else {
        if (field.options) { result = field.options; }
        else if (field.models && this.options[name]) result = this.options[name];
        else {
          result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
        }
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      if (this.role.add) {
        actions.header = { name: 'add', icon: 'plus', path: `list-${this.modelName}/add` };
      }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
          buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/` };
        }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        if (that.role.delete) {
          buttons.delete = { name: 'delete', icon: 'trash', key: 'id' }
        }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111" + pageTransaction, payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key], query: { page_transaction: pageTransaction.toString() } })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          console.log(data.data);
          this.deleteId = data.data[action.key]
          // this.countRelationship = (data.data.users_club_expiration_aggregate.aggregate.count + data.data.users_setting_sports_aggregate.aggregate.count + data.data.users_stripe_aggregate.aggregate.count + data.data.bookings_aggregate.aggregate.count);
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      let variables = {
        'data_update': { deleted: true },
        'where_key': {
          'id': { '_eq': this.deleteId }
        }
      }
      await this.$apollo.mutate({
        mutation: DataServices.updateData('visit_report'),
        variables: variables
      }).then(async (res) => {
        console.log("res", res);
        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successfully', life: 3000 })
        this.componentKey++;
      });
    }
  },

}
</script>
<style lang="css">
.p-calendar .p-datepicker {
  width: 280px;
  padding: 0px;
  padding-top: 5px;
}

.p-calendar .p-datepicker td,
.p-calendar .p-datepicker td a {
  padding: 0px !important;
}

.p-calendar .p-datepicker td span {
  padding: 0px !important;
  width: 30px !important;
  height: 30px !important;
}

.p-datepicker .p-datepicker-header {
  padding: 5px !important;
}

.p-button-warning .p-button-label {
  color: #1D76BC;
}

.p-button-warning .pi-times {
  color: #1D76BC;
}

.color-box {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
</style>